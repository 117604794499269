var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _c("label", { staticClass: "d-none", attrs: { for: "streetsearch" } }, [
      _vm._v("Suche nach Straße")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "input-group" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchText,
            expression: "searchText"
          }
        ],
        staticClass: "form-control",
        attrs: {
          id: "streetsearch",
          type: "text",
          placeholder: "Suche nach Straße",
          autocomplete: "off",
          disabled: _vm.streetSelected
        },
        domProps: { value: _vm.searchText },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchText = $event.target.value
            },
            _vm.filterStreets
          ],
          keydown: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown"
                ])
              ) {
                return null
              }
              return _vm.onArrowDown($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              return _vm.onArrowUp($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onEnter($event)
            }
          ],
          blur: function($event) {
            return _vm.closeSuggestions()
          }
        }
      }),
      _vm._v(" "),
      _vm.streetSelected
        ? _c("div", { staticClass: "input-group-append" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary",
                attrs: { type: "button" },
                on: { click: _vm.clearSelection }
              },
              [_vm._v("\n                Löschen\n            ")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.streetSelected ? _c("i", { staticClass: "fa fa-search" }) : _vm._e()
    ]),
    _vm._v(" "),
    _vm.showSuggestions
      ? _c(
          "ul",
          { staticClass: "autocomplete-items" },
          _vm._l(_vm.suggestions, function(suggestion, index) {
            return _c(
              "li",
              {
                key: index,
                class: {
                  "autocomplete-active": index === _vm.selectedSuggestion
                },
                on: {
                  click: function($event) {
                    return _vm.selectSuggestion(suggestion)
                  },
                  mouseenter: function($event) {
                    return _vm.onMouseEnter(index)
                  }
                }
              },
              [_vm._v("\n            " + _vm._s(suggestion) + "\n        ")]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }