<template>
    <div class="form-group">
        <label for="streetsearch"
            class="d-none">Suche nach Straße</label>
        <div class="input-group">
            <input id="streetsearch"
                class="form-control"
                type="text"
                v-model="searchText"
                placeholder="Suche nach Straße"
                @input="filterStreets"
                autocomplete="off"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
                @keydown.enter="onEnter"
                @blur="closeSuggestions()"
                :disabled="streetSelected" />
            <!-- Hier wird der Löschen-Button hinzugefügt -->
            <div class="input-group-append"
                v-if="streetSelected">
                <button class="btn btn-outline-secondary"
                    type="button"
                    @click="clearSelection">
                    Löschen
                </button>
            </div>
            <i v-if="!streetSelected"
                class="fa fa-search"></i>
        </div>
        <ul v-if="showSuggestions"
            class="autocomplete-items">
            <li v-for="(suggestion, index) in suggestions"
                :key="index"
                @click="selectSuggestion(suggestion)"
                @mouseenter="onMouseEnter(index)"
                :class="{ 'autocomplete-active': index === selectedSuggestion }">
                {{ suggestion }}
            </li>
        </ul>

    </div>
</template>

<script>
    export default {
    	voVueComponent: 'streetsearch',

    	data() {
    		return {
    			searchText: "",
    			suggestions: [],
    			selectedSuggestion: -1,
    			streetSelected: false,
    		};
    	},
    	computed: {
    		showSuggestions() {
    			return this.suggestions.length > 0;
    		},
    	},
    	methods: {
    		closeSuggestions() {
    				setTimeout(() => {
    					const myInputElement = document.getElementById('streetsearch');
    					myInputElement.value = '';
    					this.suggestions = [];
    				}, "1000");
    			},
    			sortSuggestions() {
    				this.suggestions.sort((a, b) => a.localeCompare(b));
    			},
    			filterStreets() {
    				const val = this.searchText.toUpperCase();
    				this.suggestions = this.streets.filter((street) =>
    					street.toUpperCase().startsWith(val)
    				);

    				// Sortiere die Vorschläge alphabetisch
    				this.sortSuggestions();

    				this.selectedSuggestion = -1;
    			},
    			selectSuggestion(suggestion) {
    				this.searchText = suggestion;
    				this.suggestions = [];
    				var ids;
    				const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
    				const storedData = JSON.parse(localStorage.getItem('uniqueStreetsData_' + suffix));
    				//console.log("Ausgewählte Straße:", this.searchText);
    				if (storedData) {

    					if (storedData.data.includes(this.searchText)) {
    						// Die Straße ist vorhanden, daher können wir die IDs lesen
    						const streetIds = storedData.ids[this.searchText];
    						this.$parent.lastUrl = undefined;
    						this.$parent.setStreet(streetIds);
    						this.streetSelected = true;
    						//console.log(`IDs für ${this.searchText}:`, streetIds);
    					}


    				}

    			},
    			clearSelection() {
    				// Diese Methode wird aufgerufen, um die ausgewählte Straße zu löschen
    				this.searchText = "";
    				this.streetSelected = false;
    				this.$parent.lastUrl = undefined;
    				this.$parent.setStreet();
    			},
    			onArrowDown() {
    				if (this.selectedSuggestion < this.suggestions.length - 1) {
    					this.selectedSuggestion++;
    				}
    			},
    			onArrowUp() {
    				if (this.selectedSuggestion > -1) {
    					this.selectedSuggestion--;
    				}
    			},
    			onEnter() {
    				if (this.selectedSuggestion !== -1) {
    					this.searchText = this.suggestions[this.selectedSuggestion];
    					this.suggestions = [];
    				}
    			},
    			onMouseEnter(index) {
    				this.selectedSuggestion = index;
    			},
    	},
    	props: {
    		streets: Array, // Das Array der Straßennamen von der Elternkomponente übergeben
    	},
    };
</script>

<style>
    .autocomplete-items {
    	list-style-type: none;
    	padding: 0;
    	margin: 0;
    	border: 1px solid #ccc;
    	display: block;
    }

    .autocomplete-items li {
    	padding: 8px;
    	cursor: pointer;
    }

    .autocomplete-items li:hover {
    	background-color: #00427A;
    }

    .autocomplete-active {
    	background-color: #00427A;
    	color: white;
    }
</style>